<template>
  <div class="container detail guide request">
    <div class="top">
      <div class="inner">
        <h2>가격조사 의뢰 안내</h2>
      </div>
    </div>
    <div class="base">
      <section>
        <article class="inner">
          <div>
            <h4>안내</h4>
            <span
              >- 아래 사항들을 기재하시어 이메일로 보내주시면 저희 담당자가 연락
              드리겠습니다.</span
            >
            <ul>
              <li>&#183; 제품명 (모델명)</li>
              <li>&#183; 제품 설명 (스펙, 출시일 또는 예정일)</li>
              <li>
                &#183; 희망 참여 회원 수 또는 기간 (동시 조건 불가) 및 희망 진행
                시기
                <span>예) 500명 또는 5일간</span>
              </li>
              <li>&#183; 선정 회원들에게 제공 가능한 혜택 내용</li>
              <li>
                &#183; 기타 (신제품의 경우 이미지나 모델명 등 제공 불가시
                가명으로 가능)
              </li>
              <li>&#183; 전화 연락처</li>
            </ul>
            <p>* 조사 의뢰 비용은 별도 안내드립니다.</p>
            <p>이메일 : ulmani5955@gmail.com</p>
            <!-- <div class="buttonWrap">
              <button class="point">신청 의뢰서 다운받기</button>
            </div> -->
          </div>
        </article>
      </section>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchPostDetail, createPostLike } from "@/api/post";
import { createFunding, fundingCancel } from "@/api/funding";
import { uploadFile } from "@/api/file";
import { format } from "@/mixins/format";

export default {
  mixins: [format],

  data() {
    return {
      moment: moment,
      modalVisible: false,
      contactVisible: false,
      fundingVisible: false,
      postId: "",
      title: "",
      targetAmount: 0,
      totalAmount: 0,
      fundingAmount: 0,
      fundingCount: 0,
      fundingRate: 0,
      deadLine: 0,
      endAt: "",
      startAt: "",
      likesCount: 0,
      isFunding: true,
      images: [],
      likes: [],
      tel: "",
      company: "",
    };
  },
  computed: {
    ...mapState(["userId", "isLogin"]),
  },
  mounted() {
    this.postId = this.$route.query.id;
    this.getPost();
  },
  methods: {
    getPost() {
      fetchPostDetail(this.postId).then((res) => {
        if (res.data.status == 200) {
          this.title = res.data.data.title;
          this.company = res.data.data.company;
          this.tel = res.data.data.tel;
          this.targetAmount = res.data.data.targetAmount;
          this.totalAmount = res.data.data.totalAmount;
          this.fundingCount = res.data.data.funding.length;
          this.fundingAmount = res.data.data.fundingAmount;
          this.images = res.data.data.images;
          this.startAt = res.data.data.startAt;
          this.endAt = res.data.data.endAt;
          this.isFunding =
            res.data.data.funding.indexOf(this.userId) > -1 ? false : true;
          this.likesCount = res.data.data.likes.length;
          this.likes = res.data.data.likes;
          this.deadLine = Math.ceil(
            (new Date(this.endAt).getTime() - new Date().getTime()) /
              (1000 * 60 * 60 * 24)
          );
          // this.deadLine = moment(this.endAt)
          //   .format("YYYY.MM.DD")
          //   .diff(moment(new Date().format("YYYY.MM.DD")), "day");
          this.fundingRate = (this.totalAmount / this.targetAmount) * 100;
        }
      });
    },

    handleLike() {
      if (!this.isLogin) {
        this.$router.push("/login");
        return alert("로그인 후 이용해세요.");
      }
      let data = {
        postId: this.postId,
      };
      createPostLike(data).then((res) => {
        if (res.data.status == 200) {
          this.likesCount = res.data.data.likes.length;
          this.likes = res.data.data.likes;
        } else {
          return alert(res.data.message);
        }
      });
    },
    sendFile() {
      this.file = this.$refs.images.files[0];
      if (this.file == undefined) {
        return;
      }
      let formdata = new FormData();
      formdata.append("file", this.file);
      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.file = "";
            this.images.push(res.data.url);
          } else {
            return alert(res.data.message);
          }
        } else {
          return alert("서버오류 입니다. 다시 파일을 업로드 해주세요.");
        }
      });
    },
    handleFunding() {
      if (!this.isLogin) {
        this.$router.push("/login");
        return alert("로그인 후 이용해세요.");
      }
      let data = {
        postId: this.postId,
      };
      createFunding(data).then((res) => {
        if (res.data.status == 200) {
          this.modalVisible = true;
          this.fundingVisible = true;
          this.isFunding = false;
          this.$store.dispatch("getUserFundingList", this.userId);
        } else {
          return alert(res.data.message);
        }
      });
    },
    handleFundingCancel() {
      if (!this.isLogin) {
        this.$router.push("/login");
        return alert("로그인 후 이용해세요.");
      }
      let data = {
        postId: this.postId,
        fundingId: this.postId,
      };
      fundingCancel(data).then((res) => {
        if (res.data.status == 200) {
          this.isFunding = true;
          this.$store.dispatch("getUserFundingList", this.userId);
        } else {
          return alert(res.data.message);
        }
      });
    },
    close() {
      this.modalVisible = false;
      this.contactVisible = false;
      this.fundingVisible = false;
    },
    handleContactModal() {
      this.modalVisible = true;
      this.contactVisible = true;
    },
    handleUrlCopy() {
      var dummy = document.createElement("input");
      var text = location.href;

      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      this.$alert("원하는 곳에 붙여넣기 해주세요.", "URL이 복사되었습니다.", {
        confirmButtonText: "OK",
        center: true,
      });
    },
  },
};
</script>
